import React from "react";

export default function MiniInfoButton({
  text = "نامشخص",
  color = "gray",
  size = "text-nowrap",
  extraClassName = "",
}) {
  return (
    <div
      className={`${size} w-fit px-2 border border-solid border-${color}-200 hover:bg-${color}-100 hover:text-${color}-700 transition-all ease-in-out duration-300 bg-${color}-50 text-${color}-600 p-1 rounded-lg ${extraClassName}`}
    >
      {text}
    </div>
  );
}
