import { loginURL } from "src/settings/config";
import { notify } from "src/services/Notification/Notification";

export const AuthorizationRequired = (message) => {
    
    if (loginURL == window.location.href) {
        return;
    }
    notify(message, "error");
    localStorage.removeItem("access_token");
    setTimeout(() => {
        window.location.replace(`${loginURL}`);
    }, 1000);
};
