import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { routes } from "./routes";
import userContext from "src/context/userContext";
import { useEffect, useState } from "react";
import { get } from "./services/HttpClient";

const router = createBrowserRouter(routes, {
    basename: process.env.PUBLIC_URL,
});

function App() {
    const [user, setUser] = useState({});

    // GET user FROM BACKEND
    const getUser = async () => {
        const result = await get("/user");
        if (result) {
            setUser(result.body);
        }
    };
    useEffect(() => {
        getUser();
    }, []);

    return (
        <userContext.Provider value={{ user, setUser }}>
            <RouterProvider router={router} />
        </userContext.Provider>
    );
}

export default App;
