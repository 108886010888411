import { Formik, Form, Field, ErrorMessage } from "formik";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import PageHeader from "src/components/PageHeader";
import FormikField from "src/components/FormikField/FormikField";
import { useNavigate } from "react-router-dom";
import { PiBriefcase } from "react-icons/pi";
import { get, post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import { useContext, useEffect, useState } from "react";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import userContext from "src/context/userContext";
import TwinButton from "src/components/TwinButton";

export default function AddJob() {
    const { user } = useContext(userContext)
    const [orgOptions, setOrgOptions] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    useEffect(() => {
        setOrgOptions([{
            value: user?.organization?.id,
            label: user?.organization?.name
        }])
    }, [])

    const getUsers = async () => {
        let result = await get(`/organizations/${user.organization.id}/users`);
        if (result.body) {
            setUserOptions(result.body.map(function (single) {
                return {
                    value: single.id,
                    label: single.fullName
                }
            }))
        }
    }
    useEffect(() => {
        getUsers();
    }, [])



    return (
        <>
            <PageHeader title="تعریف شغل جدید" />
            <Formik
                enableReinitialize
                initialValues={{
                    title: "",
                    organizationId: user?.organization?.id,
                    orgUnit: "",
                    users: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setIsLoading(true)
                    const result = await post("/jobs", {
                        title: values.title,
                        description: null,
                        organizationId: values.organizationId,
                        orgUnit: values.orgUnit,
                        analyzeStatus: "0",
                        approveStatus: "0",
                        data: JSON.stringify({ "step": -1 }),
                    });
                    if (!result) {
                        notify("خطایی رخ داده است", "error")
                    }
                    let jobId = result.body.insertedId;
                    await post("/analyses", {
                        jobId,
                        users: values.users,
                    });
                    notify("شغل با موفقیت به سیستم اضافه شد")
                    navigate(pathname.slice(0, pathname.search("/add")));
                    setIsLoading(false)
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="flex flex-col bg-white dark:bg-black rounded-2xl p-10 px-20">
                            <HeaderDescriptive
                                title="اطلاعات شغل"
                                description="اطلاعات زیر را برای اضافه شدن شغل تکمیل نمایید"
                            >
                                <PiBriefcase size={40} />
                            </HeaderDescriptive>
                            <div className="flex gap-10 mt-10 ">
                                <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                    <FormikField
                                        inputName="title"
                                        label="عنوان شغل"
                                        placeholder="عنوان شغل را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        inputName="organizationId"
                                        type="select"
                                        label="سازمان"
                                        placeholder="سازمان کاربر را وارد کنید..."
                                        disabled={true}
                                        errors={errors}
                                        options={orgOptions}
                                        values={values}
                                        value={values.organizationId}
                                        setFieldValue={setFieldValue}
                                    />
                                    <FormikField
                                        inputName="orgUnit"
                                        label="واحد سازمانی"
                                        placeholder="واحد سازمانی را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        inputName="users"
                                        type="multiselect"
                                        label="کاربران"
                                        placeholder="کاربرانی که در تحلیل این شغل نقش دارند را مشخص کنید..."
                                        errors={errors}
                                        options={userOptions}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                            </div>
                            <TwinButton
                                rejectHandle={() => {
                                    navigate(pathname.slice(0, pathname.search("/add")));
                                }}
                                isLoading={isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
