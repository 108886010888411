import { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

let styles = {
    width: "0",
    height: "0",
    border: "7px solid transparent",
    borderTop: "0",
    borderBottom: "15px solid rgb(0, 0, 0)",
    borderRadius: "10px 0px 0px 10px",
}
export default function Infobox({ text }) {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (
        <>
            <div className="relative">
                <AiOutlineInfoCircle
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="text-[#C6C6C6] cursor-help"
                />
                <span
                    className={`${isHover ? "visible opacity-100 -translate-y-1" : "invisible opacity-0"} z-10 transition-all duration-300 ease-in-out absolute -top-4 left-[120px] w-[160px] h-auto transform -translate-x-1/2 bg-black text-white text-sm p-3 rounded-xl`}
                >
                    <div
                        className="absolute -left-2 top-[22px] rotate-[29deg]"
                        style={styles}
                    ></div>
                    {text}
                </span>
            </div>
        </>
    );
}
