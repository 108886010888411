import { Formik, Form, Field, ErrorMessage } from "formik";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import PageHeader from "src/components/PageHeader";
import FormikField from "src/components/FormikField/FormikField";
import { useLocation, useNavigate } from "react-router-dom";
import { PiBriefcase } from "react-icons/pi";
import { get, post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import { useContext, useEffect, useState } from "react";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import userContext from "src/context/userContext";
import TwinButton from "src/components/TwinButton";
import { getData } from "src/actions/getData";

export default function EditJob() {




    // THIS COMPONENT IS TODO



    const { user } = useContext(userContext)
    const [orgOptions, setOrgOptions] = useState([])
    const [jobData, setJobData] = useState()
    const [userOptions, setUserOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const jobId = params.get("id");


    useEffect(() => {
        getData(setJobData, `/jobs/${jobId}`)
    }, [])

    useEffect(() => {
        setOrgOptions([{
            value: user?.organization?.id,
            label: user?.organization?.name
        }])
    }, [])

    return (
        <>
            <PageHeader title="ویرایش شغل" />
            <Formik
                enableReinitialize
                initialValues={{
                    title: jobData?.title,
                    organizationId: user?.organization?.id,
                    orgUnit: jobData?.orgUnit,
                    // users: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setIsLoading(true)
                    const result = await post(`/jobs/${jobId}`, {
                        title: values.title,
                        orgUnit: values.orgUnit,
                    });
                    if (!result) {
                        notify("خطایی رخ داده است", "error")
                    }
                    notify("شغل با موفقیت به روز رسانی شد")
                    setIsLoading(false)
                    navigate(pathname.slice(0, pathname.search("/edit")));
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="flex flex-col bg-white dark:bg-black rounded-2xl p-10 px-20">
                            <HeaderDescriptive
                                title="ویرایش شغل"
                                description="اطلاعات زیر را برای ویرایش شغل تغییر دهید"
                            >
                                <PiBriefcase size={40} />
                            </HeaderDescriptive>
                            <div className="flex gap-10 mt-10 ">
                                <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                    <FormikField
                                        inputName="title"
                                        label="عنوان شغل"
                                        placeholder="عنوان شغل را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    <FormikField
                                        inputName="organizationId"
                                        type="select"
                                        label="سازمان"
                                        placeholder="سازمان کاربر را وارد کنید..."
                                        disabled={true}
                                        errors={errors}
                                        options={orgOptions}
                                        values={values}
                                        value={values.organizationId}
                                        setFieldValue={setFieldValue}
                                    />
                                    <FormikField
                                        inputName="orgUnit"
                                        label="واحد سازمانی"
                                        placeholder="واحد سازمانی را وارد کنید..."
                                        // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                        errors={errors}
                                    />
                                    {/* <FormikField
                                        inputName="users"
                                        type="multiselect"
                                        label="کاربران"
                                        placeholder="کاربرانی که در تحلیل این شغل نقش دارند را مشخص کنید..."
                                        errors={errors}
                                        options={userOptions}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    /> */}
                                </div>
                            </div>
                            <TwinButton
                                rejectHandle={() => {
                                    navigate(pathname.slice(0, pathname.search("/edit")));
                                }}
                                isLoading={isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
