import { useContext, useEffect, useState } from "react";
import Table from "src/components/Table";
import { PiEyeDuotone } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { PiFilePdf } from "react-icons/pi";

import { get } from "src/services/HttpClient";
import { IoTrashOutline } from "react-icons/io5";
import { TbCheckupList } from "react-icons/tb";
import DeleteModal from "src/components/DeleteModal";
import { useNavigate } from "react-router-dom";
import userContext from "src/context/userContext";
import { notify } from "src/services/Notification/Notification";
import Loading from "src/components/Loading";
import MiniInfoButton from "src/components/MiniInfoButton";

export default function JobTable() {
    const navigate = useNavigate();

    const { user } = useContext(userContext)

    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const [pdfGenerating, setPdfGenerating] = useState(false)

    let token = localStorage.getItem("access_token")

    const getJobs = async () => {
        if (user.organization) {
            const result = await get(`/organizations/${user.organization.id}/jobs`);
            setData(result.body)
        } else {
            const result = await get("/jobs");
            setData(result.body)
        }
    }
    useEffect(() => {
        getJobs();
    }, [reload])

    const columns = [
        {
            id: "id",
            cell: (info) => info.getValue() + 1000,
            header: "کد شغل",
            footer: (info) => info.column.id,
        },
        {
            id: "title",
            cell: (info) => info.getValue(),
            header: <span>عنوان شغل</span>,
            footer: (info) => info.column.id,
        },
        {
            id: "users",
            cell: (info) => info.getValue()?.map((item) => {
                return item.fullName
            }).join("، "),
            header: "کاربران",
            footer: (info) => info.column.id,
        },
        {
            id: "organization",
            cell: (info) => info.getValue()?.name,
            header: "سازمان",
            footer: (info) => info.column.id,
        },
        {
            id: "orgUnit",
            cell: (info) => info.getValue(),
            header: "واحد سازمانی",
            footer: (info) => info.column.id,
        },
        {
            id: "analyzeStatus",
            cell: (info) => info.getValue() == 0 ? <MiniInfoButton text="تحلیل نشده" color="gray" /> : <MiniInfoButton text="تحلیل شده" color="green" />,
            header: "وضعیت تحلیل",
            footer: (info) => info.column.id,
        },
        {
            id: "approveStatus",
            cell: (info) => info.getValue() == 0 ? <MiniInfoButton text="تایید نشده" color="gray" /> : <MiniInfoButton text="تایید شده" color="green" />,
            header: "وضعیت تائید",
            footer: (info) => info.column.id,
        },
        {
            id: "id",
            cell: (info) => (
                <div className="flex gap-2">
                    <div
                        className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_DATA_ENTRY_URL}?t=${token}&id=${info.getValue()}&step=finish`)
                        }}
                    >
                        <span className="whitespace-nowrap">مشاهده گزارش</span>
                    </div>

                    <div
                        className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_DATA_ENTRY_URL}?t=${token}&id=${info.getValue()}&step=start`)
                        }}
                    >
                        <TbCheckupList className="text-green-500" />
                    </div>
                    <div
                        className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                        onClick={async () => {
                            setPdfGenerating(true)
                            notify("گزارش شما در حال آماده سازی است، لطفا چند دقیقه منتظر بمانید و صفحه را ترک نکنید")
                            const requestOptions = {
                                method: 'GET',
                                headers: { 'Content-Type': 'application/json' }
                            };

                            await fetch(`${process.env.REACT_APP_PDF_GN}/salam/t=${token}&id=${info.getValue()}&step=finish`, requestOptions)
                                .then((res) => {
                                    return res.blob();
                                })
                                .then((blob) => {
                                    const href = window.URL.createObjectURL(blob);
                                    const link = document.createElement('a');
                                    link.href = href;
                                    link.setAttribute('download', `${info.row.original.title}.pdf`); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                })
                                .catch((err) => {
                                    notify("خطایی رخ داده است، لطفا چند دقیقه دیگر مجددا امتحان کنید", "error")
                                    return Promise.reject({ Error: 'Something Went Wrong', err });
                                })
                            setPdfGenerating(false)
                        }}
                    >
                        <PiFilePdf className="text-orange-500" />
                    </div>
                    <div
                        className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                        onClick={() => {
                            navigate(
                                `/app/job-management/job/edit?id=${info.getValue()}`
                            );
                        }}
                    >
                        <FiEdit className="text-blue-500" size={22} />
                    </div>
                    <div
                        className="cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg"
                        onClick={() => {
                            setSelectedRow(info.getValue())
                            setActive(true)
                        }}
                    >
                        <IoTrashOutline className="text-red-500" />
                    </div>

                </div>
            ),
            header: "عملیات",
            footer: (info) => info.column.id,
            enableColumnFilter: false,
        },
    ];


    return (
        <>
            {
                pdfGenerating
                &&
                <Loading title="PDF گزارش شما در حال آماده سازی است، لطفا تا اتمام فرآیند منتظر بمانید" />
            }
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف شغل"
                id={selectedRow}
                url="/jobs"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
        </>
    );
}
