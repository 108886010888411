import React from 'react'
import { Link } from 'react-router-dom'

export default function QuickAccessBox({ title, subTitle, to }) {
    return (
        <Link to={to} className="bg-white dark:bg-black cursor-pointer flex justify-start flex-col mt-3 gap-2 border border-solid border-gray-200 rounded-2xl w-full p-5 ">
            <span className="font-semibold text-lg">{title}</span>
            <span className="text-gray-400">{subTitle}</span>
        </Link>
    )
}
