import { post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";

export async function postData(url, data, additionalAction = () => {}) {
    try {
        const result = await post(url, data);
        if (result && result.success) {
            notify(result.message);
            additionalAction();
        }
    } catch (error) {
        notify("خطایی رخ داده است", "error");
    }
}
